<template>
  <el-row class="invoicing-tools">
    <el-col
      :xs="24"
      :sm="12"
    >
      <outstanding-invoices />
    </el-col>
    <el-col
      :xs="24"
      :sm="12"
    >
      <automated-collections />
    </el-col>
  </el-row>
</template>

<script>
import OutstandingInvoices from './outstanding-invoices/OutstandingInvoices'
import AutomatedCollections from './collections/Collections'

export default {
  name: 'InvoicingTools',
  components: {
    'automated-collections': AutomatedCollections,
    'outstanding-invoices': OutstandingInvoices
  }
}
</script>

<style lang="scss" scoped>
.invoicing-tools {
  ::v-deep > div {
    padding: 2em;
    text-align: center;
    .title {
      margin: 0;
      font-size: 1.5rem;
    }
  }
}
</style>
