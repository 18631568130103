<template>
  <el-dialog
    class="outstanding-invoices-dialog"
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <template #title>
      <h3>Export Unpaid Invoices PDFs</h3>
      <p>Packaged invoices will be sent to <i>{{ emailAddress }}</i></p>
    </template>

    <el-form
      ref="form"
      :model="form"
      label-width="90px"
      :rules="rules"
    >
      <el-form-item
        label="Company"
        prop="company_id"
      >
        <el-select
          v-model="form.company_id"
          filterable
          remote
          placeholder="Search companies..."
          :remote-method="fetchCompanies"
          :loading="loadingCompanies"
        >
          <el-option
            v-for="company in companies"
            :key="company.id"
            :label="company.human_name"
            :value="company.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="Properties"
        prop="property_ids"
      >
        <el-transfer
          v-model="propertiesExcluded"
          v-loading="loadingProperties"
          filterable
          :data="propertyList"
          :titles="['Include', 'Exclude']"
          :props="{
            key: 'id',
            label: 'name'
          }"
        />
      </el-form-item>

      <el-form-item
        class="date-range"
        label="Date Range"
        prop="date_start"
      >
        <el-radio-group v-model="useDateRange">
          <el-radio :label="false">
            Search all records
          </el-radio>
          <el-radio :label="true">
            Search within a date range
          </el-radio>
        </el-radio-group>
        <el-date-picker
          v-model="dates"
          type="daterange"
          range-separator="To"
          start-placeholder="Choose start"
          end-placeholder="Choose end"
          value-format="yyyy-MM-dd"
          :picker-options="{ firstDayOfWeek: 1 }"
          :clearable="false"
          :disabled="!useDateRange"
        />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">
        Cancel
      </el-button>
      <el-button
        type="primary"
        @click="submit"
      >
        Export Invoices
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import RooofAPI from '@/services/api/rooof'
export default {
  name: 'OutstandingInvoicesDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      form: {
        company_id: null,
        property_ids: [],
        date_start: null,
        date_end: null
      },
      rules: {
        company_id: [
          { required: true, message: 'Please select a company', trigger: 'blur' }
        ],
        property_ids: [
          {
            validator: (rule, value, callback) => {
              if (this.propertyList.length === this.propertiesExcluded.length) {
                return callback(new Error('At least one property must be included'))
              }
              return callback()
            },
            trigger: 'blur'
          }
        ],
        date_start: [
          {
            validator: (rule, value, callback) => {
              if (this.useDateRange && !this.dates.length) {
                return callback(new Error('Please select a date range'))
              }
              return callback()
            },
            trigger: 'blur'
          }
        ]
      },
      companies: [],
      loadingCompanies: false,
      propertyList: [],
      propertiesExcluded: [],
      loadingProperties: false,
      useDateRange: false,
      dates: []
    }
  },
  computed: {
    emailAddress () {
      return this.$store.state.auth.account && this.$store.state.auth.account.email
    },
    selectedCompany () {
      return this.form.company_id
    }
  },
  watch: {
    selectedCompany (id) {
      if (id) {
        this.fetchProperties(id)
      }
    }
  },
  methods: {
    /**
     * Get companies that match the search query from the API.
     *
     * @param {String} query
     */
    async fetchCompanies (query) {
      if (!query) {
        return
      }
      this.loadingCompanies = true
      const response = await RooofAPI.companies.summary({ search: query })
      this.companies = response
      this.loadingCompanies = false
    },
    /**
     * Get properties that belong to a given company from the API.
     *
     * @param {Number} companyId
     */
    async fetchProperties (companyId) {
      try {
        this.loadingProperties = true
        const properties = await RooofAPI.companies.propertySummary(companyId)
        this.propertyList = properties.map(property => { return { id: property.id, name: property.name } })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loadingProperties = false
      }
    },
    /**
     * Validates the form and emits a submit event with the request payload.
     */
    submit () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const payload = JSON.parse(JSON.stringify(this.form))

          // We can leave property_ids = [] to send all properties
          if (this.propertiesExcluded.length) {
            payload.property_ids = this.propertyList.filter(property =>
              !this.propertiesExcluded.includes(property.id)).map(property => property.id)
          }
          if (this.useDateRange) {
            payload.date_start = this.dates[0]
            payload.date_end = this.dates[1]
          } else {
            delete payload.date_start
            delete payload.date_end
          }
          this.$emit('submit', payload)
          this.reset()
        }
      })
    },
    /**
     * Resets the form fields and validation.
     */
    reset () {
      this.$refs['form'].resetFields()
      this.$refs['form'].clearValidate()
      this.propertyList = []
      this.propertiesExcluded = []
      this.dates = []
      this.useDateRange = false
    },
    /**
     * Emits an event to close the dialog.
     */
    cancel () {
      this.$emit('close')
      this.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.outstanding-invoices-dialog {
  text-align: left;
  .el-dialog__header {
    h3 {
      margin: 0;
    }
    p {
      margin: 0.75em 0 0;
    }
  }
  .el-select {
    width: 100%;
  }
  ::v-deep .el-transfer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-transfer-panel {
      width: 100%;
    }

    .el-transfer__buttons {
      display: flex;
      flex-direction: column-reverse;
      .el-button {
        margin: 5px 0;
      }
    }
  }
  .date-range {
    ::v-deep .el-form-item__label {
      line-height: 1;
    }
    .el-radio-group {
      width: 100%;
      .el-radio {
        width: 100%;
        margin-bottom: 1em;
      }
    }
  }
}
</style>
