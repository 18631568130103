<template>
  <div id="collections">
    <h2 class="title">
      Send Monthly Statement
    </h2>
    <p>
      Send company-wide monthly statement to all contacts set to receive monthly statements.
    </p>
    <el-button
      :loading="loading"
      type="primary"
      @click="confirm"
    >
      {{ buttonText }}
    </el-button>
  </div>
</template>

<script>
import InvoicesAPI from '@/services/api/invoices'

export default {
  name: 'Collections',
  data () {
    return {
      loading: false,
      sent: false
    }
  },
  computed: {
    buttonText () {
      if (this.loading) {
        return 'Working...'
      }
      return this.sent ? 'Job Started!' : 'Send Monthly Statement'
    }
  },
  methods: {
    async confirm () {
      try {
        await this.$confirm('This will start the automated collections process. Once started, it cannot be stopped. ' +
                            'Are you sure you wish to continue?', {
          title: 'Are you sure?',
          type: 'warning',
          confirmButtonText: 'Show me the money!',
          cancelButtonText: 'Oh hell naw',
          confirmButtonClass: 'el-button--primary',
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false
        })
        this.runSummary()
      } catch (err) {
        // user cancelled, do nothing
      }
    },
    async runSummary () {
      this.loading = true
      try {
        await InvoicesAPI.summaries.run()
        this.sent = true
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
      this.loading = false
    }
  }
}
</script>
