<template>
  <div>
    <h2 class="title">
      Export Unpaid Invoices PDFs
    </h2>
    <p>
      Export and send all unpaid invoices as PDFs for a company to your Rooof email address.
    </p>
    <el-button
      type="primary"
      :loading="loading"
      @click="showDialog = true"
    >
      Export Unpaid Invoices PDFs
    </el-button>
    <export-dialog
      :show="showDialog"
      @close="showDialog = false"
      @submit="submit"
    />
  </div>
</template>

<script>
import OutstandingInvoicesDialog from './_components/OutstandingInvoicesDialog'
import InvoicesAPI from '@/services/api/invoices'
export default {
  name: 'OutstandingInvoices',
  components: {
    'export-dialog': OutstandingInvoicesDialog
  },
  data () {
    return {
      showDialog: false,
      loading: false
    }
  },
  methods: {
    /**
     * Submits request to API to send outstanding invoices.
     *
     * @param {Object} payload
     */
    async submit (payload) {
      try {
        this.loading = true
        this.showDialog = false
        await InvoicesAPI.outstanding.email(payload)
        this.$message({
          message: 'Your request has been sent! You should receive an email within a few minutes with the selected invoices.',
          type: 'success',
          duration: 3500
        })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
